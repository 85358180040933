<script setup lang="ts">
    import { ref, onMounted, onUnmounted, watch } from 'vue'
    import { useRoute } from 'vue-router';

    import Header from './Header.vue';
    import HamburgerMenu from './HamburgerMenu.vue';

    // Handle changing colors off the buttons depending on the page
    const route = useRoute()
    const whiteBgButtons = ref(false)

    var pinkViews = [
        "/cookieverklaring", "/privacyverklaring", "/algemene-voorwaarden",
        "/inloggen",
    ]

    import jobs from '../data/jobs.json'
    jobs.forEach(job => {
        pinkViews.push("/werken-bij-lenn/" + job.slug)
    });

    import newsItems from '../data/news.json'
    newsItems.forEach(news => {
        pinkViews.push("/nieuws/" + news.slug)
    });

    watch(route, () => {
        checkDoNeedAnimation()
        
        // Set buttons according to page
        whiteBgButtons.value = route.path.includes("/werkgever")
        showPinkBG.value = showPinkBG.value = pinkViews.indexOf(route.path) == 1
        showNavBg.value = false

        checkShowPinkBGView()
    })

    // DONT animate on views
    var showAnimation = ref(true)
    const checkDoNeedAnimation = () => {
        const dontAnimMe = [
        "/inloggen"
        ]
        showAnimation.value = dontAnimMe.indexOf(route.path) >= 0
    }

    const checkShowPinkBGView = () => {
        if (!showNavBg.value) {
            showPinkBG.value = pinkViews.indexOf(route.path) >= 0
        }
    }

    // Handle if menu is shown or not
    var showNavbar = ref(true)
    var showNavBg = ref(false)
    var showPinkBG = ref(false)
    const lastScrollPosition = ref(0)

    const onScroll = (event: Event) => {
      showNavbar.value = !(window.pageYOffset > 100)

      if (lastScrollPosition.value > window.pageYOffset) {
        showNavbar.value = true
        showNavBg.value = true
      }

      if (!showNavbar.value) {
        showNavBg.value = false
      }

      checkShowPinkBGView()

      lastScrollPosition.value = window.pageYOffset
    }

    onMounted(() => {
        window.addEventListener("scroll", onScroll)
        // removeAnimation()
    })

    onUnmounted(() => {
        window.removeEventListener("scroll", onScroll)
    })

</script>

<template>
    <div class="menu-background" :class="{ 'navbar-bg-show': showNavBg, 'navbar-bg-pinkviews': showPinkBG, 'navbar-bg-hide': !showNavBg}">
        <div class="top-header" :class="{ 'anim--hidden': showAnimation, 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}">
            <Header/>
        </div>
        <div class="navBar" :class="{ 'anim--hidden': showAnimation}" >
            <div class="logo-container">
                <span :class="{ 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}">
                <RouterLink to="/">
                    <div class="logos">
                        <div class="small-window-logo">
                            <img class="logo" :class="{ 'anim--hidden': showAnimation, 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}" src="../assets/lenn_logo_full.svg"/>
                        </div>
                        <div class="anim" id="logo-animation">
                            <img src="@/assets/lenn_logo_sun.svg"/>
                        </div>
                        <div class="origin-logo-animation">
                            <img class="logo" :class="{ 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}" src="../assets/lenn_logo_partial.svg"/>
                        </div>
                    </div>
                </RouterLink>
            </span>
            </div>
            <div class="navBarButtons">
                <RouterLink to="/aanmelden" :class="{ 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}"><button :class="{ 'white-bg-buttons': whiteBgButtons }" class="menu-btn-primary">{{ $t("nav.signup") }}</button></RouterLink>
                <!-- <a href="https://lenn.minddistrict.nl/" target="_blank" :class="{ 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}"><button :class="{ 'white-bg-buttons': whiteBgButtons }" class="menu-btn-primary">inloggen</button></a> -->
                <RouterLink to="/inloggen" target= "_blank" :class="{ 'navbar--hidden': !showNavbar, 'navbar--show': showNavbar}"><button :class="{ 'white-bg-buttons': whiteBgButtons }" class="menu-btn-primary">{{ $t("nav.login") }}</button></RouterLink>
                <span class="sticky"><HamburgerMenu/></span>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .anim--hidden {
        display: none !important;    
    }

    .navbar-bg-show {
        background-color: rgb(232, 78, 15, 1.0);
        transition: all 1.0s ease;
    }

    .navbar-bg-pinkviews {
        background-color: var(--magenta);
        transition: all 1.0s ease;
    }

    .navbar-bg-hide {
        background-color: transparent;
        transition: all 0.s ease;
    }

    /* Animation */
    .anim {
        position: absolute;
        display: flex;
        height: 240px;
        top: 20vh;
        left: 50%;
        transform: translate(-50%, 0%);
        animation-delay: 1s;
        animation-iteration-count: 1;
        animation-name: logo-animation;
        animation-duration: 2.0s;
        animation-fill-mode: forwards
    }
    .small-window-logo {
        display: none;
    }

    .logo-container {
        width: 50%;
    }

    @keyframes logo-animation {
        0% {
            height: 240px;
        }
        100% {
            height: 50px;
            top: 42px;
            left: 6%; 
        }
    }

    .origin-logo-animation img {
        width: 0px;
        height: 78px;
        object-fit: cover;
        animation-delay: 3.3s;
        animation-iteration-count: 1;
        animation-name: reveal;
        animation-duration: 0.6s;
        transition-timing-function: ease-out;
        animation-fill-mode: forwards;
    }

    @keyframes reveal {
        0% {
            margin-left: 135px;
            width: 0%;
            left: 200px;
        }
        5% {
            margin-left: 145px;
        }
        100% {
            width: 300px;
        }
    }

    /* Animation end */
    * {
    box-sizing: border-box;
    }

    .logo {
        height: 78px;
        margin: 16px;
    }
    .navBarButtons {
        margin: 16px;
        /* align-items: baseline; */
    }

    /* White BG Buttons */
    .white-bg-buttons {
        border: 2px solid var(--black);
    }

    .white-bg-buttons:hover {
        border: 2px solid var(--black);
        color: white;
        background-color: var(--black);
    }

    .menu-background {
        width: 100vw;
        height: 135px;        
        position: fixed;
        z-index: 40;
        left: 0;
    }
    .navBar {
        max-width: 1040px;
        margin: auto;
        /* margin-top: 18px; */
        position: relative;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        justify-content: space-between;
    }

    .navbar--hidden {
        transition: 0.4s all ease-out;
        transform: translate3d(0, -100%, 0);
        opacity: 0;
        visibility: hidden;
    }

    .navbar--show {
        transition: 0.4s all ease-out;
        transform: translate3d(0, 0, 0);
        opacity: 1.0;
        visibility: visible;
    }

    @media (max-width: 850px) {
        .menu-background {
            height: 215px;
        }
        
        .navBar {
            flex-direction: column;
        }

        .anim, .origin-logo-animation {
            display: none;
        }

        .menu-btn-primary {
            font-size: 20px;
        }

        .small-window-logo {
            display: block;
        }
        .logo-container {
            width: 90%;
        }
    }

    @media (min-width: 1040px) {
        .navBar {
            left: 50%;
            margin-left: -520px;
        }
    }

    button {
        margin-right: 8px;
    }
</style>