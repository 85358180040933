<script setup lang="ts">
  import { Row, Col } from 'ant-design-vue';
  import i18n from '@/i18n';

  function onLangChange(newLang: String) {
    var lang = "nl"
    if (newLang == "en") {
      lang = "en"
    }

    i18n.global.locale.value = lang

    let d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = "lang=" + lang + ";" + expires + ";path=/;secure";
  }
</script>

<template>
  <div class="header-container">
    <div class="header-content">
      <Row>
        <Col><a href="tel://0858228262" class="call-link" target="_blank">{{ $t("footer.callUs")}} <span style="text-decoration: underline;">085-8228262</span></a> </Col>
        <Col><span style="margin-left: 4px;">{{ $t("footer.or")}}</span> <a href="https://wa.me/31649682978" class="call-link" style="text-decoration: underline;" target="_blank"> WhatsApp</a></Col>
        <Col flex="auto"></Col>
        <Col><button @click="onLangChange('en')" class="lang-btn"><img src="@/assets/icons/en.svg"/></button></Col>
        <Col><button @click="onLangChange('nl')" class="lang-btn"><img src="@/assets/icons/nl.svg"/></button></Col>
      </Row>
    </div>
  </div>
</template>

<style scoped>
.header-container {
  background-color: white;
  height: 25px;
  line-height: 25px;
  width: 100%;
}

.call-link {
  color: var(--black)
}

.header-content {
  padding: 0px 16px;
  max-width: 1040px;
  margin: auto;
  align-content: space-between;
}

.whatsapp-btn {
  color: var(--black);
  margin: 0px;
}

.whatsapp-btn img {
  width: 100px;
  max-height: 15px;
}

.lang-btn {
  background: none;
  border: none;
  margin: 0px;
  padding: 0px 6px 0px 0px;
  cursor: pointer;
}

.lang-btn img {
  max-height: 15px;
  /* max-width: 30px; */
}
</style>